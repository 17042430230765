import "./HeaderContent.css";

function HeaderContent() {
    return (
        <div id="HeaderContent">
            <div className="HeaderContentContainer">
                <p className="text-homepage-my-name-is font-bold">
                    Hello, my name is
                </p>

                <p className="text-homepage-name font-medium">
                    Samuele Perticarari.
                </p>

                <p className="text-homepage-what-i-do font-book">
                    I design cloud infrastructures.
                </p>

                <p className="text-homepage-what-i-do-description font-medium">
                    I'm a Cloud Engineer @ Deloitte Consulting with experience in cloud infrastructure, migration and networking with coding background.<br/>
                    Passion and eagerness to learn are my defining traits.
                </p>
            </div>
        </div>
    );
}

export default HeaderContent;
